import React, { useState } from "react"
import { TextInput } from "../components/profissionaisSaude/Inputs/InputText"
import ProImg from "./../assets/images/profissional-saude1.svg"
import Header from "../components/profissionaisSaude/Header"
import Button from "../components/profissionaisSaude/Inputs/Button"
import { Link } from "gatsby"
import Footer from "../components/profissionaisSaude/Footer"
import { Container } from "../components/compartilhados/Container"
import { Main, ContentWrapper, ImageWrapper, SubtitleWrapper, Span } from "../styles/home"

export default function ProfissionaisSaude() {
  const regexEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i
  const [emailText, setEmailText] = useState("")
  const [emailTextError, setEmailTextError] = useState("")
  const [passwordText, setPasswordText] = useState("")
  const [passwordTextError, setPasswordTextError] = useState("")

  function handleSubmitForm(event) {
    event.preventDefault()

    setEmailTextError("")
    setPasswordTextError("")

    if (!emailText) {
      setEmailTextError("Este campo é obrigatório!")
    }

    if (emailText && !emailText.toLowerCase().match(regexEmail)) {
      setEmailTextError(
        "Por favor, utilize um formato de e-mail válido. Por exemplo: email@dominio.com.br"
      )
    }

    if (!passwordText) {
      setPasswordTextError("Este campo é obrigatório!")
    }

    if (passwordText && passwordText.length < 6) {
      setPasswordTextError("Sua senha deve conter no mínimo 6 caracteres")
    }

    if (emailTextError || passwordTextError) {
      return
    }
  }

  return (
    <Container>
      <Header />
      <Main>
        <ContentWrapper>
          <SubtitleWrapper>
            <h1>Boas-vindas à Lacrei Saúde</h1>
            <Span />
            <p>Entre ou crie sua conta Lacrei</p>
          </SubtitleWrapper>
          <form onSubmit={handleSubmitForm}>
            <TextInput
              onFocus={() => setEmailTextError("")}
              onChange={event => setEmailText(event.target.value)}
              value={emailText}
              placeholder="Digite seu e-mail"
              label="E-mail"
              error={emailTextError}
            />
            <TextInput
              onFocus={() => setPasswordTextError("")}
              onChange={event => setPasswordText(event.target.value)}
              value={passwordText}
              placeholder="Digite sua senha"
              label="Senha"
              error={passwordTextError}
              isPassword
            />
            <div className="button-container">
              <Button title="Entrar" type="submit" noLink />
              <Button
                link="/saude/cadastrar-estado"
                title="Criar conta"
                variant="secondary"
              />
            </div>
            <Link to="/saude/redefinicao-senha" className="esquecisenha">
              Esqueci minha senha
            </Link>
          </form>
        </ContentWrapper>
        <ImageWrapper>
          <ProImg className="pro-image" alt="Descrição da imagem" />
          <span className="image-credits">
            Ilustrado por{" "}
            <a
              className="anchor-image"
              target={"_blank"}
              href="https://br.freepik.com/"
            >
              Freepik
            </a>
          </span>
        </ImageWrapper>
      </Main>
      <Footer />
    </Container>
  )
}
