import styled from "styled-components"

export const Main = styled.div`
  width: 100%;
  max-width: 68.75rem;
  min-height: calc(100vh - 20.25rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 1rem;
  gap: 2rem;

  .esquecisenha {
    color: ${({ theme }) => theme.colors.secondary.health_green};
    text-decoration: underline;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 1020px) {
    padding: 1rem 2.5rem;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing.m};
  }
`

export const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 27.5rem;

    .button-container {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
`

export const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;

  h1 {
    font-size: 1.5rem;
  }
`

export const Span = styled.span`
  width: 35%;
  padding: 0.063rem 0;
  background-color: #018762;
`

export const ImageWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  .pro-image {
    max-width: 33.5rem;
    max-height: 27.688rem;
  }

  .image-credits {
    color: #515151;
    font-size: 0.75rem;
    position: absolute;
    bottom: -1rem;
    right: 4rem;
    width: 6rem;
    white-space: nowrap;
    
    .anchor-image {
      cursor: pointer;
      color: ${({theme}) => theme.colors.gray.dark};
      text-decoration: underline;
    }
  }
`